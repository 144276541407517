export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',

  SEARCH_BOX_INPUT_CLASS: 'header-global-search-input',
  SEARCH_BOX_BUTTON_CLASS: 'button icon-left',

  PRODUCT_REPEATER_CLASS: 'product-grid grid grid-2-col grid-3-col-tablet grid-4-col-desktop',

  GARAGE_SIZE: '<span class="garage-size head-slot-cart-link-quantity" key="garage-size">{{size}}</span>',

  MIN_PRICE: 'Min',
  MAX_PRICE: 'Max',

  SEARCH_BOX_PLACEHOLDER: 'Search by SKU, VIN or Keyword',

  VERIFY_FITMENT_TITLE: 'Verify fitment with your Porsche',
  SELECT_YOUR_VEHICLE: 'Select Your Porsche',
  SELECT_YOUR_VEHICLE_BLOCK: 'Select Your Porsche',
};
